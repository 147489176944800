





























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { Message } from "element-ui";
import store from "@/store";
import { checkPhone } from "@/utils/common";

@Component({
  name: "Login",
})
export default class extends Vue {
  // 倒计时
  codeTime = 0;
  private login = {
    username: "",
    verify_code: "",
  };

  ///////////////////////////////////////////
  codeloading = false;
  getCodeTime() {
    if (this.codeloading) {
      return "获取中";
    }
    return this.codeTime
      ? (this.codeTime < 10 ? "0" : "") + this.codeTime + "秒"
      : "获取验证码";
  }
  GetCode(): void {
    // 是否正在获取验证码，由于网络过慢，防重复点击
    if (this.codeloading) {
      return;
    }
    // 是否倒计时完毕
    if (this.codeTime) {
      return;
    }
    // 验证手机号
    if (!checkPhone(this.login.username)) {
      return;
    }
    this.codeloading = true;
    // 调用短信接口 ajax
    api
      .getVerCode({ mobile: this.login.username })
      .then(() => {
        this.codeloading = false;
        // 60秒倒计时
        this.codeTime = 60;
        // 启用倒计时
        setTimeout(() => {
          this.TimeReduce();
        }, 1000);
      })
      .catch(() => {
        this.codeloading = false;
      });
  }

  /*
   * 获取验证码倒计时
   * 2020年4月21日
   * 周祥
   */
  TimeReduce(): void {
    // 如果倒计时没有完毕 则继续执行
    if (this.codeTime) {
      // 倒计时递减
      this.codeTime--;
      // 延迟一秒执行
      setTimeout(() => {
        this.TimeReduce();
      }, 1000);
    }
  }

  private loading = false;

  goLogin() {
    if (this.loading) {
      return;
    }
    if (!checkPhone(this.login.username)) {
      return;
    }
    if (!this.login.verify_code) {
      Message.error("请输入验证码");
      return;
    }

    this.loading = true;

    api
      .getToken({
        mobile: this.login.username,
        verifycode: this.login.verify_code,
      })
      .then((data) => {
        if (data.sys.length === 0) {
          this.$alert("暂无权限，请联系管理员分配权限！", "系统提示");
          return;
        }
        store.dispatch("tags/NameView", data.user.username);
        api.getDics().then((res) => {
          store.dispatch("system/addConfig", res);
        });
        store.dispatch("user/Login", data);
        this.$router.push("/home");
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
